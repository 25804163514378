import { Container, Row, Col } from "react-bootstrap";
import styles from "./styles.module.css";
import Links from "./Links";
import { ParallaxLayer } from "@react-spring/parallax";

const Intro = () => {
  return (
    <ParallaxLayer
      offset={0}
      speed={0.2}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container>
        <div className={styles.logo}>
          <img src="/logo.png" alt="Tarun Nune" className={styles.img} />
        </div>
        <Row className={styles.row}>
          <Col sm={12} className={styles.intro}>
            <div className={styles.content}>
              <p className={styles.name}>Tarun Nune</p>
              <p className={styles.description}>
                Software Development Engineer @ Amazon
              </p>
            </div>
          </Col>
          <div className={styles.links}>
            <Links />
          </div>
        </Row>
      </Container>
    </ParallaxLayer>
  );
};

export default Intro;
