import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import styles from "./styles.module.css";
import IntroLayer from "../Intro";
import Layer from "../Shapes/Layers";
import { layers } from "../../utils/layers";
import { projects } from "../../utils/projects";
import AboutLayer from "../About";
import ProjectsLayer from "../Projects";
import ContactLayer from "../Contact";

const Main = () => {
  const pages = 3 + projects.length;
  return (
    <main>
      <Parallax pages={pages} className={styles.parallax}>
        {layers.map((layer, idx) => (
          <Layer
            key={idx}
            offset={layer.offset}
            speed={layer.speed}
            margin={layer.margin}
            size={layer.size}
            deg={layer.deg}
          />
        ))}
        <IntroLayer />
        <ParallaxLayer
          offset={0.8}
          speed={1.2}
          style={{ background: "rgba(255, 255, 255, 0.01)" }}
        />
        <AboutLayer />
        <ProjectsLayer projects={projects} />
        <ContactLayer offset={pages - 1} />
      </Parallax>
    </main>
  );
};

export default Main;
