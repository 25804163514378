import { ParallaxLayer } from "@react-spring/parallax";
import { FC } from "react";
import { Project } from "../../utils/projects";
import Tags from "./Tags";
import { useMediaQuery } from "react-responsive";

const ProjectLayer: FC<Project & { idx: number }> = ({
  name,
  uri,
  github,
  imageUri,
  techs,
  description,
  idx,
}) => {
  const isSmall = useMediaQuery({ maxWidth: "768px" });

  return (
    <>
      <ParallaxLayer offset={idx + (isSmall ? 2.05 : 2.1)} speed={0.8}>
        <p className="p-title">{name}</p>
      </ParallaxLayer>
      <ParallaxLayer offset={idx + (isSmall ? 2.25 : 2.3)} speed={0.6}>
        <img src={imageUri} alt={name} />
      </ParallaxLayer>
      <ParallaxLayer offset={idx + (isSmall ? 2.57 : 2.25)} speed={0.4}>
        <div className="p-description">{description}</div>
      </ParallaxLayer>
      <ParallaxLayer offset={idx + (isSmall ? 2.78 : 2.5)} speed={0.5}>
        <Tags tags={techs} />
      </ParallaxLayer>
      <ParallaxLayer offset={idx + (isSmall ? 2.93 : 2.8)} speed={0.6}>
        <div className="p-links">
          {github !== undefined && (
            <a
              type="button"
              rel="noreferrer"
              target="_blank"
              href={github}
              className="p-github"
            >
              Github Repo
            </a>
          )}
          {uri !== undefined && (
            <a
              type="button"
              rel="noreferrer"
              target="_blank"
              href={uri}
              className="p-uri"
            >
              Visit Website
            </a>
          )}
        </div>
      </ParallaxLayer>
    </>
  );
};

export default ProjectLayer;
