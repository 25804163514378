export const layers = [
  { offset: 0.15, speed: -0.2, margin: 20, size: "90", deg: 60 },
  { offset: 0.2, speed: 0.3, margin: 80, size: "50", deg: 82 },
  { offset: 0.8, speed: 0.3, margin: 30, size: "70", deg: 61 },
  { offset: 1.2, speed: 0.6, margin: 40, size: "60", deg: 80 },
  { offset: 1.1, speed: -0.2, margin: 60, size: "90", deg: 61 },
  { offset: 1.4, speed: -0.2, margin: 9, size: "50", deg: 41 },
  { offset: 1.5, speed: 0.3, margin: 43, size: "75", deg: 19 },
  { offset: 0.6, speed: 1.3, margin: 65, size: "60", deg: 34 },
  { offset: 2.1, speed: 0.7, margin: 34, size: "57", deg: 69 },
  { offset: 2.6, speed: -0.3, margin: 62, size: "65", deg: 59 },
  { offset: 2.5, speed: 1.6, margin: 30, size: "93", deg: 52 },
  { offset: 3.7, speed: 1.0, margin: 87, size: "65", deg: 2 },
  { offset: 3.8, speed: -0.3, margin: 65, size: "62", deg: 19 },
  { offset: 3.8, speed: 0.7, margin: 24, size: "84", deg: 75 },
  { offset: 4.9, speed: 0.5, margin: 55, size: "67", deg: 159 },
  { offset: 4.0, speed: -1.3, margin: 43, size: "74", deg: 49 },
  { offset: 4.1, speed: 0.6, margin: 67, size: "59", deg: 221 },
  { offset: 5.2, speed: 2.0, margin: 26, size: "86", deg: 125 },
  { offset: 5.2, speed: 1.3, margin: 10, size: "60", deg: 44 },
  { offset: 6.1, speed: 0.8, margin: 72, size: "55", deg: 63 },
  { offset: 6.3, speed: 0.3, margin: 78, size: "75", deg: 80 },
  { offset: 6.4, speed: 0.2, margin: 25, size: "60", deg: 34 },
];
