const api = "https://alfie-portfolio-backend.herokuapp.com";

export const sendMessage = async (
  name: string,
  email: string,
  message: string
) => {
  let response: any = await fetch(`${api}/send-message`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      email,
      message,
    }),
  });
  response = await response.json();
  if (response.success) return true;
  return false;
};
