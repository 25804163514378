const Tags = ({ tags }: { tags: string[] }) => {
  return (
    <div className="p-tags">
      {tags.map((tag, idx) => (
        <span key={idx} className="p-tag">
          {tag}
        </span>
      ))}
    </div>
  );
};

export default Tags;
