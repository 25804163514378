import { ParallaxLayer } from "@react-spring/parallax";
import "./styles.css";

const About = () => {
  return (
    <>
      <ParallaxLayer offset={1} speed={0.5} className="about-p-bg" />
      <ParallaxLayer offset={1.2} speed={0.4} className="about-title">
        About Me
      </ParallaxLayer>
      <ParallaxLayer offset={1.4} speed={0.7} className="about-description">
        I am Tarun Nune, a 21-year-old guy who likes solving problems using data
        structures and algorithms, and playing video games. I've recently
        graduated from CMR CET, Hyderabad. I work at Amazon as an SDE and prior
        to that, I've interned at Amazon for 6 months. I use most of my free
        time to explore new tech, play games, watch anime and read manga.
      </ParallaxLayer>
    </>
  );
};

export default About;
