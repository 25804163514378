import { ParallaxLayer } from "@react-spring/parallax";
import { FC } from "react";
import Shape from "../Shapes";

interface Props {
  offset: number;
  speed: number;
  deg?: number;
  margin: number;
  size: string;
}

const Layer: FC<Props> = ({ offset, speed, margin, size, deg = 0 }) => {
  return (
    <ParallaxLayer {...{ offset, speed }}>
      <Shape margin={margin + "%"} size={size} deg={deg} />
    </ParallaxLayer>
  );
};
export default Layer;
