import { FC } from "react";
import { useSpring, animated } from "@react-spring/web";
import styles from "./styles.module.css";

interface Props {
  margin: string;
  size: string;
  deg: number;
}

const Shape: FC<Props> = ({ margin, size, deg }) => {
  const { value } = useSpring({
    value: deg + 360,
    from: {
      value: deg,
    },
    loop: true,
    config: {
      duration: 7000,
    },
  });

  return (
    <animated.div
      className={styles.shape}
      style={{
        width: size + "px",
        height: size + "px",
        marginLeft: margin,
        transform: value.to((deg) => `rotate(${deg}deg)`),
      }}
    ></animated.div>
  );
};

export default Shape;
