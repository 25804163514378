export interface LinkType {
  name: string;
  uri: string;
  icon: string;
}

export const links: LinkType[] = [
  {
    name: "Facebook",
    uri: "https://www.facebook.com/tarunalfie/",
    icon: "fa-facebook",
  },
  {
    name: "Instagram",
    uri: "https://www.instagram.com/alfie.was.taken/",
    icon: "fa-instagram",
  },
  {
    name: "Github",
    uri: "https://www.github.com/alfie2775/",
    icon: "fa-github",
  },
  {
    name: "Linkedin",
    uri: "https://www.linkedin.com/in/tarun-nune/",
    icon: "fa-linkedin",
  },
];
