import "./links.css";
import { links, LinkType } from "../../utils/social-media-links";

const Link = ({ link, idx }: { link: LinkType; idx: number }) => {
  return (
    <div key={idx} title={link.name} className="link">
      <a href={link.uri} target="_blank" rel="noreferrer">
        <span className="fa-icon">
          <i className={`fab ${link.icon}`}></i>
        </span>
      </a>
    </div>
  );
};

const Links = () => {
  return (
    <div className="wrapper">
      {links.map((link, idx) => (
        <Link link={link} idx={idx} />
      ))}
    </div>
  );
};

export default Links;
