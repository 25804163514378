import type { Project } from "../../utils/projects";
import { FC } from "react";
import { ParallaxLayer } from "@react-spring/parallax";
import ProjectLayer from "./ProjectLayer";
import "./styles.css";

interface Props {
  projects: Project[];
}

const ProjectsLayer: FC<Props> = ({ projects }) => {
  return (
    <>
      {projects.map((project, idx) => (
        <div key={idx} className={`project project-${idx}`}>
          <ParallaxLayer offset={2 + idx} speed={0.2} className="p-parallax" />
          <ProjectLayer {...project} idx={idx} />
        </div>
      ))}
    </>
  );
};

export default ProjectsLayer;
