import { ParallaxLayer } from "@react-spring/parallax";
import { useState, FC } from "react";
import { sendMessage } from "../../utils/api";
import "./styles.css";

const Result: FC<{ result: string }> = ({ result }) => {
  return <p className="result">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{result}</p>;
};

const Contact: FC<{ offset: number }> = ({ offset }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const canSend = name && email && message;

  const handleSubmit = async (e: any) => {
    if (canSend) {
      setResult("Sending...");
      const isSent = await sendMessage(name, email, message);
      setResult(
        isSent
          ? "Your message is succesfully sent."
          : "Your message isn't sent, try again."
      );
      setName("");
      setEmail("");
      setMessage("");
    }
  };

  return (
    <>
      <ParallaxLayer
        offset={offset}
        speed={0.4}
        style={{ background: "rgba(255, 255, 255, 0.08)" }}
      />
      <ParallaxLayer offset={offset + 0.1} speed={0.6}>
        <div className="c-title">Get In Touch With Me</div>
      </ParallaxLayer>
      <ParallaxLayer offset={offset + 0.25} speed={0.5}>
        <label className="c-l c-name">Name</label>
        <input
          autoComplete="off"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="c-in c-in-name"
        />
      </ParallaxLayer>
      <ParallaxLayer offset={offset + 0.4} speed={0.3}>
        <label className="c-l c-email">E-Mail</label>
        <input
          type="email"
          autoComplete="off"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="c-in c-in-email"
        />
      </ParallaxLayer>
      <ParallaxLayer offset={offset + 0.55} speed={0.1}>
        <label htmlFor="message" className="c-l c-msg">
          Message
        </label>
        <textarea
          rows={5}
          autoCorrect="on"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="c-in c-in-msg"
        />
      </ParallaxLayer>
      <ParallaxLayer offset={offset + 0.8} speed={0.03}>
        <div className="c-btn-h">
          <div>
            <button
              style={{
                cursor: canSend ? "pointer" : "not-allowed",
              }}
              className="c-btn"
              onClick={handleSubmit}
            >
              Send Message
            </button>
          </div>
          <Result result={result} />
        </div>
      </ParallaxLayer>
      <ParallaxLayer offset={offset + 0.89} speed={0.6}>
        <p className="copy-wright">&#169; 2021, All rights reserved.</p>
      </ParallaxLayer>
    </>
  );
};

export default Contact;
