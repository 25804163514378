export type Project = {
  name: string;
  uri?: string;
  github?: string;
  imageUri: string;
  techs: string[];
  description: string;
};

export const projects: Project[] = [
  {
    name: "Kite Chat App",
    uri: "https://kite.vercel.app",
    github: "https://github.com/alfie2775/chat-app-frontend",
    imageUri: "/project-images/kite.svg",
    techs: [
      "ReactJS",
      "Redux",
      "Typescript",
      "SCSS",
      "Socket.io",
      "NodeJS",
      "ExpressJS",
      "MongoDB",
    ],
    description:
      "A real-time chat app where users can add friends and create groups for real-time texting. You get notified whenever a new message or friend request comes.",
  },
  {
    name: "Flynt IDE",
    uri: "https://flynt-ide.netlify.app",
    github: "https://github.com/alfie2775/Flynt",
    imageUri: "/project-images/flynt.svg",
    techs: [
      "ReactJS",
      "SCSS",
      "Typescript",
      "Redux",
      "NodeJS",
      "ExpressJS",
      "MongoDB",
    ],
    description:
      "An online IDE where you can compile your code. Creating an account gives access to adding templates for a specific programming language and also saving your programs.",
  },
  {
    name: "Music App",
    uri: "https://alfie-music.netlify.app",
    github: "https://github.com/alfie2775/Music-App",
    imageUri: "/project-images/music-app.svg",
    techs: ["ReactJS", "SCSS"],
    description: "Wanna listen to some of the best music? Visit the website.",
  },
  {
    name: "E-Book Store",
    github: "https://github.com/alfie2775/e-book-store-front-end",
    imageUri: "/project-images/book-store.svg",
    techs: ["ReactJS", "Redux", "SCSS", "NodeJS", "ExpressJS", "MongoDB"],
    description: "An online store where you can purchase your favourite books.",
  },
  {
    name: "Weather App",
    uri: "https://alfie-weather-app.netlify.app",
    github: "https://github.com/alfie2775/Weather-App",
    imageUri: "/project-images/weather-app.svg",
    techs: ["ReactJS", "SCSS"],
    description:
      "A weather app that lets you find the weather of any city in the world.",
  },
];
